import React, { useCallback, useEffect, useState } from "react"
import { Link } from "gatsby"

import { Layout } from "../../components/Layout"
import {
  formatPriceWithoutCents,
  formatShowcaseTime,
  formatUnitTime,
} from "../../utils"

import "./styles.css"

type ROIResultsProps = {
  location: {
    state: {
      results: {
        moneySavedWithIrisAgentPerMonth: number
        totalTeamTimeSavedPerMonth: number
        totalEngineeringTimeSavedPerMonth: number
        numOfEscalationsAvoidedPerMonth: number
        totalAgentTimeSavedInTaggingPerMonth: number
        totalAgentTimeSavedInResolvingTicketsPerMonth: number
        reduceResolutionAndCustomerWaitTime: number
      }
    } | null
  }
}

export default function ROIResults({ location }: ROIResultsProps) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [tooltipHoverTimeout, setTooltipHoverTimeout] = useState<NodeJS.Timer>(
    null
  )

  const { state } = location

  const { results } = state || {
    results: {
      moneySavedWithIrisAgentPerMonth: 0,
      totalTeamTimeSavedPerMonth: 0,
      totalEngineeringTimeSavedPerMonth: 0,
      numOfEscalationsAvoidedPerMonth: 0,
      totalAgentTimeSavedInTaggingPerMonth: 0,
      totalAgentTimeSavedInResolvingTicketsPerMonth: 0,
      reduceResolutionAndCustomerWaitTime: 0,
    },
  }

  const handleClickOutside = useCallback(() => {
    if (isTooltipVisible) {
      setIsTooltipVisible(false)
    }
  }, [isTooltipVisible])

  const handleToggleTooltipVisibility = useCallback(
    (isHover: boolean) => {
      if (isHover) {
        clearTimeout(tooltipHoverTimeout)

        setIsTooltipVisible(true)
        return
      }

      setTooltipHoverTimeout(oldState => {
        clearTimeout(oldState)

        return setTimeout(() => {
          setIsTooltipVisible(false)
        }, 200)
      })
    },
    [tooltipHoverTimeout]
  )

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <Layout
      keywords="ROI results"
      title="Results of ROI Calculator by IrisAgent"
      description="View personalized results of ROI Calculator by IrisAgent"
    >
      <section className="app-roi-result-section">
        <div className="app-roi-result-content">
          <div className="app-roi-result-title">
            <h1>
              <span className="highlight">
                Save{" "}
                {formatPriceWithoutCents(
                  results.moneySavedWithIrisAgentPerMonth
                )}
                <span className="small-text">/month</span>
              </span>{" "}
              with IrisAgent!
            </h1>

            <div className="app-roi-results-tooltip-wrapper">
              <img
                onClick={() => setIsTooltipVisible(oldState => !oldState)}
                onMouseOver={() => handleToggleTooltipVisibility(true)}
                onMouseOut={() => handleToggleTooltipVisibility(false)}
                src="/img/featured/info.svg"
                alt="info"
              />
              <span
                className="app-tooltip-roi-content"
                onMouseOver={() => handleToggleTooltipVisibility(true)}
                onMouseOut={() => handleToggleTooltipVisibility(false)}
                style={
                  isTooltipVisible
                    ? {
                        opacity: 1,
                        visibility: "visible",
                      }
                    : {
                        opacity: 0,
                        visibility: "hidden",
                      }
                }
              >
                <h6>How do we calculate ROI?</h6>

                <p>This calculation is based on some assumptions like:</p>

                <ol>
                  <li>
                    Time to manually tag a ticket is assumed to be 2 minutes.
                  </li>
                  <li>
                    Engineering team will take at least 1 hour to solve an
                    escalated case.
                  </li>
                  <li>
                    IrisAgent’s proactive AI can reduce the number of
                    escalations by 30%.
                  </li>
                  <li>
                    IrisAgent’s proactive AI can reduce the time to resolve
                    tickets by 30%
                  </li>
                </ol>

                <p>
                  If you want custom calculation for your company please{" "}
                  <a href="/contact">contact us</a>.
                </p>
              </span>
            </div>
          </div>

          <div className="app-roi-results">
            <div className="app-roi-results-main">
              <div className="roi-main-cards">
                <div className="app-card-wrapper app-card-blue">
                  <span className="app-card-data">
                    <span>
                      {formatPriceWithoutCents(
                        results.moneySavedWithIrisAgentPerMonth
                      )}
                    </span>

                    <span className="app-card-description">Total saving</span>
                  </span>

                  <span className="icon">
                    <img
                      src="/img/featured/dollar-sign.svg"
                      alt="Dollar Sign"
                    />
                  </span>
                </div>

                <div className="app-card-wrapper app-card-green">
                  <span className="app-card-data">
                    <span>
                      {formatShowcaseTime(
                        results.totalTeamTimeSavedPerMonth * 60
                      )}
                    </span>

                    <span className="app-card-description">
                      Total time saved
                    </span>
                  </span>

                  <span className="icon">
                    <img src="/img/featured/timer.svg" alt="Clock" />
                  </span>
                </div>
              </div>

              <div className="app-label-calculate-by-time">
                <span>Per month</span>
              </div>
            </div>

            <div className="app-roi-result-details">
              {results.totalAgentTimeSavedInTaggingPerMonth > 0 && (
                <div className="app-small-card-wrapper">
                  <span>
                    {formatShowcaseTime(
                      results.totalAgentTimeSavedInTaggingPerMonth * 60
                    )}
                  </span>

                  <span className="app-card-description">
                    Total agent time saved in tagging
                  </span>
                </div>
              )}

              <div className="app-small-card-wrapper">
                <span>
                  {formatShowcaseTime(
                    results.totalAgentTimeSavedInResolvingTicketsPerMonth * 60
                  )}
                </span>

                <span className="app-card-description">
                  Total agent time saved in resolving tickets
                </span>
              </div>

              <div className="app-small-card-wrapper">
                <span>
                  {formatShowcaseTime(
                    results.totalEngineeringTimeSavedPerMonth * 60
                  )}
                </span>

                <span className="app-card-description">
                  Total engineering time saved
                </span>
              </div>

              <div className="app-small-card-wrapper">
                <span>
                  {Math.round(results.numOfEscalationsAvoidedPerMonth)}
                </span>

                <span className="app-card-description">
                  # of escalations avoided
                </span>
              </div>

              <div className="app-small-card-wrapper">
                <span>
                  {formatUnitTime(
                    results.reduceResolutionAndCustomerWaitTime * 60
                  )}
                  <span className="tiny-card-title">/ticket</span>
                </span>

                <span className="app-card-description">
                  Reduce resolution and customer wait time
                </span>
              </div>
            </div>
          </div>

          <div className="app-roi-actions">
            <Link to="/roi-calculator">Re-caculate ROI</Link>

            <Link to="/" className="button-secondary">
              Back to home page
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}
